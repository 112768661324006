.custom-notification-modal {
    .ant-modal-content {
        padding: 0px !important;
        background-color: transparent;
        height: auto;
    }

    .ant-modal-close {
        top: 8px;
    }

    .ant-modal-header {
        background-image: linear-gradient(
            rgb(0, 37, 82) 30%,
            rgb(12, 58, 104) 120%
        );
        height: auto;
        padding-left: 30px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: 0px !important;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        text-align: center;
    }

    .custom-notification-modal-collapse {
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        background-image: linear-gradient(
            rgb(0, 37, 82) 30%,
            rgb(12, 58, 104) 120%
        );

        .ant-collapse-header {
            padding-bottom: 10px !important;
        }

        .ant-collapse-expand-icon {
            padding-top: 25px;
            padding-inline-end: 12px !important;
        }
    }
}
