@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&family=Raleway:wght@100;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
small {
  font-family: 'Raleway', sans-serif;
}
.svg-white {
  color: white;
}
.ant-card-hover:hover {
  cursor: pointer;
  box-shadow: 0 0 20px rgba(9, 8, 8, 0.393);
  transition: box-shadow 0.1s;
}
.warning-alert {
  background-color: #FF3939;
  font-size: 12px;
  margin-top: 32px;
  margin-inline: 60px;
  border-radius: 8px;
  font-size: 14px;
}
.warning-alert .ant-alert-content {
  padding-block: 5px;
}
.warning-alert .ant-alert-content p {
  margin: 0;
}
.warning-alert svg {
  color: white;
}
.default-alert {
  background-color: #FFFFBB;
  font-size: 12px;
  margin-top: 32px;
  margin-left: 0;
  /* Reset left margin to 0 */
  margin-right: 0;
  /* Reset right margin to 0 */
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  /* Set the width to 100% to cover the entire screen width */
}
.default-alert .ant-alert-content {
  padding-block: 5px;
}
.default-alert .ant-alert-content p {
  margin: 0;
}
.default-alert svg {
  color: black;
}
.ant-tooltip-inner {
  border-radius: 8px;
}
.ant-btn-primary {
  width: 100%;
  background: #2B61C4;
  box-shadow: 0px 4px 0px #1E4489;
  border-radius: 8px;
  color: white;
  height: 52px;
  font: normal normal 600 16px/19px Raleway;
}
.ant-btn-secondary {
  font: normal normal 600 14px/19px Open Sans;
}
.select-search-wrapper .ant-select-selector {
  height: 48px !important;
}
.select-search-wrapper .ant-btn-primary {
  width: 100%;
  background: #2B61C4;
  box-shadow: none;
  color: white;
  height: 48px;
}
.select-search-wrapper .ant-input-affix-wrapper {
  height: 48px;
  padding: 4px 11px;
}
.select-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: auto;
}
.select-search-wrapper .ant-input-group-addon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0px 4px 4px 0px;
}
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 8px 15px;
}
.ant-form-item-explain-error {
  text-align: left;
  margin-top: 5px;
}
.ant-input {
  height: 48px;
}
.ant-input-password {
  height: 48px;
}
.ant-input-password .ant-input {
  height: auto;
}
.label-italic {
  font: italic normal normal 16px/22px Open Sans;
}
.label-italic label {
  font-size: 16px;
}
.custom-select .ant-select-selector,
.custom-select .ant-picker {
  height: 48px !important;
  font-size: 15px;
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4 !important;
}
.custom-select .ant-select-selector .ant-select-selection-placeholder,
.custom-select .ant-picker .ant-select-selection-placeholder,
.custom-select .ant-select-selector .ant-select-selection-item,
.custom-select .ant-picker .ant-select-selection-item {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.ant-page-header .ant-page-header-heading-title {
  color: white;
}

#reload-button {
  background-color: #2B61C4;
  color: #FFFFFF;
  border: 2px solid #2B61C4;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}
#reload-button:hover {
  box-shadow: 0 0 3px white;
  transition: box-shadow 0.1s;
}
#reload-button-icon {
  color: white;
  margin-right: 10px;
}
.dashboard-card > .ant-card-body {
  width: 40vw;
  max-width: 200px;
}
.dashboard-card {
  margin: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(9, 8, 8, 0.393);
  border-radius: 5px;
}
.ant-page-header-heading-title,
.panel-text {
  color: white;
}
.dashboard-collapse {
  background-color: transparent;
  border-color: transparent;
}
.dashboard-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #910000;
  border-color: transparent;
}
.dashboard-collapse > .ant-collapse-item > .ant-collapse {
  background-color: transparent;
  border-color: transparent;
}
.dashboard-collapse > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-color: transparent;
  overflow-x: scroll;
}
.ant-collapse-item > .ant-collapse > .ant-collapse-item {
  border-color: transparent;
}
.dashboard-card-style {
  background: #6A4899;
  box-shadow: 0px 2px 4px #00000099;
  border-radius: 8px;
  border-color: #6A4899;
  opacity: 1;
  margin-bottom: 16px;
  cursor: pointer;
}
.dashboard-card-style-disable {
  background: #47424d;
  box-shadow: 0px 2px 4px #00000099;
  border-radius: 8px;
  border-color: #47424d;
  opacity: 1;
  margin-bottom: 16px;
  cursor: not-allowed;
}
.dashboard-card-style-disable .ant-card-body {
  padding: 0 !important;
}
.dashboard-card-style-disable h1 {
  font: 600 16px / 19px Raleway;
  color: #ffffff;
  align-self: center;
  padding-left: 16px;
  margin: 0;
}
.dashboard-card-style-disable h2 {
  font: 400 16px/19px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding-left: 16px;
  margin: 0;
}
.dashboard-card-style h1 {
  font: 600 16px / 19px Raleway;
  color: #ffffff;
  align-self: center;
  padding-left: 16px;
  margin: 0;
}
.dashboard-card-style h2 {
  font: 400 16px/19px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding-left: 16px;
  margin: 0;
}
.dashboard-system-service h3 {
  text-align: left;
  font: 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #2B61C4;
  text-transform: uppercase;
  opacity: 1;
}
.dashboard-card-style .ant-card-body {
  padding: 0 !important;
}
.dashboard-column-header {
  text-align: left;
  font: normal normal 600 24px/29px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.dashboard-header h1 {
  text-align: left;
  font: normal normal 600 20px/24px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 8px;
}
.dashboard-header h2 {
  text-align: left;
  font: normal normal normal 20px/24px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.dashboard-header h3 {
  font: normal normal normal 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0;
}
.ant-modal-header-blue .ant-modal-header {
  background: #404ccc !important;
  /* Override the default styles */
  height: 50px;
  padding-left: 30px;
  padding-top: 14px;
}
.ant-modal-header-blue .ant-modal-content {
  padding: 0;
}
.ant-modal-header-footer-padding .ant-modal-footer {
  padding: 10px 26px 16px 10px !important;
}
.custom-dashboard-card .ant-card-body {
  padding: 5px 16px 5px 16px;
  margin-bottom: 11px;
  height: 100%;
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.25);
  -webkit-filter: blur(0px);
          filter: blur(0px);
  transition: box-shadow 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out, filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
}
.custom-date-picker {
  padding: 0 !important;
}
.custom-date-picker .ant-picker-input > input {
  color: #2B61C4 !important;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: none;
}
.ant-picker-dropdown .ant-picker-header {
  background: #EFEFEF !important;
  border: #CCCCCC;
}

.custom-notification-modal {
    .ant-modal-content {
        padding: 0px !important;
        background-color: transparent;
        height: auto;
    }

    .ant-modal-close {
        top: 8px;
    }

    .ant-modal-header {
        background-image: linear-gradient(
            rgb(0, 37, 82) 30%,
            rgb(12, 58, 104) 120%
        );
        height: auto;
        padding-left: 30px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: 0px !important;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        text-align: center;
    }

    .custom-notification-modal-collapse {
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        background-image: linear-gradient(
            rgb(0, 37, 82) 30%,
            rgb(12, 58, 104) 120%
        );

        .ant-collapse-header {
            padding-bottom: 10px !important;
        }

        .ant-collapse-expand-icon {
            padding-top: 25px;
            -webkit-padding-end: 12px !important;
                    padding-inline-end: 12px !important;
        }
    }
}

.job-container {
  
  margin: 10px;
  padding: 10px;
  width:30%;
  height: 150px;
  border-radius: 10px;
  background-color: white;
  transition: 0.5s;
  cursor: pointer;

}

.job-container:hover {
  width: 33%;
  height: 180px;
  /* transform: translate(-5%, 0); */
}

.custom-update-mobile-no-form {
    .ant-form-item {
        margin-bottom: 24px !important;
    }
}

.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}

#back-link:hover {
  text-decoration: underline;
}

.styled-card {
  width: 300px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.ant-card {
  text-align: center;
}
.ownership-type-card {
  cursor: pointer;
  margin: 5px;
}
.ownership-type-card-disabled {
  opacity: 0.5;
  margin: 5px;
}
.rounded-button-bordered {
  color: #2B61C4;
  background: #FFF;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.transaction-title-in {
  font: normal normal bold 32px Raleway;
  letter-spacing: 0px;
  color: #4BFF00;
  text-transform: uppercase;
}
.transaction-additional-in {
  font: italic normal normal 32px Raleway;
  letter-spacing: 0px;
  color: #4BFF00;
}
.transaction-type-in {
  font: normal normal 600 32px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.ant-steps-label-vertical .ant-steps-item-content {
  width: 100%;
}
.form-item-dropdown .ant-form-item-control-input {
  height: 100%;
}
.form-item-dropdown .ant-form-item-control-input .ant-form-item-control-input-content {
  height: 100%;
}
.form-item-dropdown .ant-form-item-control-input .ant-form-item-control-input-content .ant-select,
.form-item-dropdown .ant-form-item-control-input .ant-form-item-control-input-content .ant-picker {
  height: 100%;
}
.form-item-dropdown .ant-form-item-control-input .ant-form-item-control-input-content .ant-select .ant-select-selector,
.form-item-dropdown .ant-form-item-control-input .ant-form-item-control-input-content .ant-picker .ant-select-selector {
  height: 100%;
}
.modal-dropdown {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
}
.approver-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
/* Add this CSS to style the horizontal line and the word "OR" */
.horizontal-line {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
  /* Gray color for the line */
  margin: 16px 0;
  /* Adjust the margin as needed */
  position: relative;
}
.or-text {
  background-color: #fff;
  /* Background color for the word "OR" */
  padding: 0 10px;
  /* Adjust the padding as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Make the background round like a circle */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  /* Optional: Add a subtle shadow effect */
}
.in-save-seller-info-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.section-card-left {
  text-align: left !important;
}

.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
}
.date-wrapper {
  width: 40%;
}
.date-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.date-wrapper .ant-picker {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.dropdown-wrapper {
  width: 20%;
  padding-right: 20px;
  padding-top: 15px;
}
.dropdown-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.dropdown-wrapper .ant-select-selector {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.horizontal-line {
  width: 30px;
  height: 2px;
  background-color: #FFF;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ant-table-wrapper.report-table .ant-table,
.ant-table-wrapper.report-table .ant-table-content {
  border-radius: 8px;
}
.ant-table-wrapper.report-table th {
  font-weight: bold;
}
.ant-table-wrapper.report-table th.ant-table-cell {
  background-color: #DFDFDF;
}
.img-btn-text {
  margin-left: 8px;
}

.site-page-header {
  color: white;
}
.step-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}
.steps-progress {
  width: 80%;
}
.steps-content {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-card {
  width: 100%;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.section-header {
  font: normal normal 600 24px/29px Raleway;
}
.section-card-left {
  text-align: left !important;
}
.header-icon-container {
  display: flex;
  align-items: center;
}
.header-success {
  color: #49BB64;
  font: normal normal bold 23px/31px Open Sans;
}
.header-danger {
  color: #E53B34;
  font-size: 20px;
  font-weight: bold;
}
.input-field {
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
  border-radius: 4px;
  margin: 10px auto 20px;
}
.input-wrapper {
  background-color: #F2F2F2;
  border-radius: 8px;
  padding: 10px 20px;
}
.italic {
  font-style: italic;
}
.company-details {
  font-weight: bold;
  margin-top: 4px;
}
.detail-label {
  font: italic normal normal 16px/19px Open Sans;
}
.detail-value {
  font: normal normal 600 16px/19px Open Sans;
}
.section-bottom-button {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}
.to-the-right {
  margin-left: auto;
}
.rectangle-button {
  padding: 10px 100px;
  width: auto;
}
.rectangle-button.ant-btn:focus {
  color: #FFF;
  background-color: #2B61C4;
}
.rectangle-button.ant-btn-primary[disabled] {
  color: #FFF;
  background: #2b61c496;
  box-shadow: 0px 4px 0px #1e4389a1;
}
.rectangle-button-danger {
  color: #FFF;
  background: #D82323;
  box-shadow: 0px 4px 0px #610000;
  border-radius: 8px;
  border: none;
  height: auto;
  padding: 10px 100px;
}
.rectangle-button-danger.ant-btn:focus {
  color: #FFF;
  background-color: #d34949;
}
.rounded-button-primary {
  color: #2B61C4;
  background: #F1F1F1;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger {
  color: #E53B34;
  background: transparent;
  border: 1px solid #E53B34;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger:hover {
  color: #E53B34;
  border: 1px solid #E53B34;
}
.link-button {
  margin: 10px auto;
}
.section-error {
  background-color: #FFCFCF;
  padding: 20px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-modal.modal-middle .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.ant-modal.modal-middle .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.detail-wrapper {
  padding-bottom: 10px;
}
.detail-wrapper span {
  display: block;
  font: italic normal normal 16px/19px Open Sans;
  padding-bottom: 8px;
}
.detail-wrapper p,
.detail-wrapper li {
  font: normal normal 600 16px/19px Open Sans;
}
.detail-wrapper ul {
  list-style-type: decimal;
  padding-left: 16px;
}
.vehicle-table .ant-table {
  margin-block: 32px;
}
.vehicle-table .ant-table th {
  font: normal normal bold 16px/22px Open Sans;
}
.vehicle-table .ant-table td {
  font: normal normal normal 16px/22px Open Sans;
}

.ant-card {
  text-align: center;
}
.ownership-type-card {
  cursor: pointer;
  margin: 5px;
}
.ownership-type-card-disabled {
  opacity: 0.5;
  margin: 5px;
}
.transaction-title-out {
  font: normal normal bold 32px/37px Raleway;
  letter-spacing: 0px;
  color: #00FFF7;
  text-transform: uppercase;
}
.transaction-additional-out {
  font: italic normal normal 32px/37px Raleway;
  letter-spacing: 0px;
  color: #00FFF7;
}
.transaction-type-out {
  font: normal normal 600 32px/37px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.ant-steps-label-vertical .ant-steps-item-content {
  width: 100% !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #E8A953 !important;
  border-color: #E8A953 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: transparent !important;
  border: 0.1px dashed #f0f0f0 !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #49BB64 !important;
  border-color: #49BB64 !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background: #c4bdbd !important;
  border-color: #c4bdbd !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.rounded-button-bordered {
  color: #2B61C4;
  background: #FFF;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
/* Add this CSS to style the horizontal line and the word "OR" */
.horizontal-line {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
  /* Gray color for the line */
  margin: 16px 0;
  /* Adjust the margin as needed */
  position: relative;
}
.or-text {
  background-color: #fff;
  /* Background color for the word "OR" */
  padding: 0 10px;
  /* Adjust the padding as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Make the background round like a circle */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  /* Optional: Add a subtle shadow effect */
}

.ant-card {
  text-align: center;
}
.ownership-type-card {
  cursor: pointer;
  margin: 5px;
}
.ownership-type-card-disabled {
  opacity: 0.5;
  margin: 5px;
}
.transaction-title-i2i {
  font: normal normal bold 32px/37px Raleway;
  letter-spacing: 0px;
  color: #FFE331;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.i2i-input {
  padding: 10px !important;
}
.transaction-additional-i2i {
  font: italic normal normal 32px/37px Raleway;
  letter-spacing: 0px;
  color: #FFE331;
}
.transaction-type-i2i {
  font: normal normal 600 32px/37px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.ant-steps-label-vertical .ant-steps-item-content {
  width: 100%;
}
.textbox-form-style {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
}
.textbox-form-style .ant-select-selector {
  border: 0px !important;
}
.i2i-pending-button span {
  margin-left: 8px;
}
.i2i-pending-button .ant-image {
  vertical-align: sub;
}
.i2i-buyer-info-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.owner-detail-header {
  font-size: 24px;
  font-weight: bold;
}

.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  min-width: 15vw;
  height: 50px;
}
.form-item > * {
  height: 50px;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.page-content {
  padding: 0px 50px;
}
.ant-page-header-heading-title {
  font: normal normal 600 32px/37px Raleway;
}
.add-new-user-button {
  background: #2B61C4 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 0px #1E4489;
  border-radius: 8px;
  text-align: center;
  font: normal normal 600 16px/19px Raleway;
  color: #FFFFFF;
  padding: 0px 90px;
  min-height: 50px;
  border: none;
  flex: 0.1 1;
}
.add-new-user-button > span {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ant-input-group-wrapper {
  max-width: 50%;
}
.ant-form-inline .ant-form-item {
  margin-right: 0px;
}
.back-button {
  border: 1px solid #2B61C4;
  color: #2B61C4;
  font-weight: bold;
  min-width: 165px;
  font: normal normal 600 16px/20px Raleway;
}
.delete-button {
  border: 1px solid #D40000;
  background: transparent;
  color: #D40000;
  margin: 10px 40px;
}
.buttons {
  height: 52px;
  padding: 10px 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  font: normal normal 600 16px/20px Raleway;
}
.search-button {
  height: 50px;
  background: #2B61C4 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 0px;
  opacity: 1;
  border-color: #2B61C4;
}
.ant-table-footer {
  display: flex;
  justify-content: flex-end;
  background: #EEF5FC 0% 0% no-repeat padding-box;
  padding: 0px !important;
}
th {
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #131313;
  padding: 15px !important;
}
.ant-breadcrumb {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
}
.ant-breadcrumb-link,
.ant-breadcrumb-separator {
  color: white !important;
}
.ant-breadcrumb-link:hover {
  color: #FFE331 !important;
}
.form-background {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.form-input-field {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
  border-radius: 4px;
  padding: 13px !important;
}
.usermanagement-info-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.custom-confirm-activate-deactivate-form {
    .ant-form-item {
        margin-bottom: 24px !important;
    }
}

.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}

.custom-verify-user-form {
    .ant-form-item {
        margin-bottom: 24px !important;
    }
}

.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}

.form-label-user .ant-form-item-label > label {
  font: italic normal normal 16px Open Sans;
  color: #333333;
}
.ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner {
  border-color: #2b61c4 !important;
}
.ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner::after {
  background-color: #2b61c4 !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner {
  border-color: #2b61c4 !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner::after {
  background-color: #2b61c4 !important;
}

.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.add-new-branch-button {
  background: #2b61c4 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 0px #1e4489;
  border-radius: 8px;
  text-align: center;
  font: normal normal 600 16px/19px Raleway;
  color: #ffffff;
  padding: 0px 90px;
  min-height: 50px;
  border: none;
  flex: 0.1 1;
}
.add-new-branch-button > span {
  margin-top: 15px;
  margin-bottom: 15px;
}
.branch-search-form .ant-input-lg,
.branch-search-form .ant-select-lg {
  font-size: 14px;
  height: 50px;
}
.branch-search-form .ant-btn {
  height: 50px;
}

.dashboard-card {
  margin: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(9, 8, 8, 0.393);
  border-radius: 5px;
}

.job-container {
  
  margin: 10px;
  padding: 10px;
  width:30%;
  height: 150px;
  border-radius: 10px;
  background-color: white;
  transition: 0.5s;
  cursor: pointer;

}

.job-container:hover {
  width: 33%;
  height: 180px;
  /* transform: translate(-5%, 0); */
}

#back-link:hover {
  text-decoration: underline;
}

.styled-card {
  width: 300px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}


#back-link:hover {
  text-decoration: underline;
}
.site-page-header {
  color: white;
}
.step-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}
.steps-progress {
  width: 80%;
}
.steps-content {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.section-card {
  width: 100%;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.section-header {
  font: normal normal 600 24px/29px Raleway;
}
.section-card-left {
  text-align: left !important;
}
.header-icon-container {
  display: flex;
  align-items: center;
}
.header-success {
  color: #49BB64;
  font: normal normal bold 23px/31px Open Sans;
}
.header-danger {
  color: #E53B34;
  font-size: 20px;
  font-weight: bold;
}
.input-field {
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
  border-radius: 4px;
  margin: 10px auto 20px;
}
.input-wrapper {
  background-color: #F2F2F2;
  border-radius: 8px;
  padding: 10px 20px;
}
.italic {
  font-style: italic;
}
.company-details {
  font-weight: bold;
  margin-top: 4px;
}
.detail-label {
  font: italic normal normal 16px/19px Open Sans;
}
.detail-value {
  font: normal normal 600 16px/19px Open Sans;
}
.section-bottom-button {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}
.to-the-right {
  margin-left: auto;
}
.rectangle-button {
  padding: 10px 100px;
  width: auto;
}
.rectangle-button.ant-btn:focus {
  color: #FFF;
  background-color: #2B61C4;
}
.rectangle-button.ant-btn-primary[disabled] {
  color: #FFF;
  background: #2b61c496;
  box-shadow: 0px 4px 0px #1e4389a1;
}
.rectangle-button-danger {
  color: #FFF;
  background: #D82323;
  box-shadow: 0px 4px 0px #610000;
  border-radius: 8px;
  border: none;
  height: auto;
  padding: 10px 100px;
}
.rectangle-button-danger.ant-btn:focus {
  color: #FFF;
  background-color: #d34949;
}
.rounded-button-primary {
  color: #2B61C4;
  background: #F1F1F1;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger {
  color: #E53B34;
  background: transparent;
  border: 1px solid #E53B34;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger:hover {
  color: #E53B34;
  border: 1px solid #E53B34;
}
.link-button {
  margin: 10px auto;
}
.section-error {
  background-color: #FFCFCF;
  padding: 20px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-modal.modal-middle .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.ant-modal.modal-middle .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.detail-wrapper {
  padding-bottom: 10px;
}
.detail-wrapper span {
  display: block;
  font: italic normal normal 16px/19px Open Sans;
  padding-bottom: 8px;
}
.detail-wrapper p,
.detail-wrapper li {
  font: normal normal 600 16px/19px Open Sans;
}
.detail-wrapper ul {
  list-style-type: decimal;
  padding-left: 16px;
}
.vehicle-table .ant-table {
  margin-block: 32px;
}
.vehicle-table .ant-table th {
  font: normal normal bold 16px/22px Open Sans;
}
.vehicle-table .ant-table td {
  font: normal normal normal 16px/22px Open Sans;
}

.blue-shadow-checkbox .ant-checkbox-inner {
  border-color: blue; /* Change the checkbox border color to blue */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add a shadow to the checkbox */
}

.blue-shadow-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: blue; /* Change the background color when checked */
  border-color: blue; /* Ensure the border color remains blue when checked */
}

.blue-shadow-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: blue; /* Change the border color on hover and focus */
}

.highlight {
  background-color: yellow; /* Highlight background color */
  /* font-weight: bold;  */
}

.transaction-modal-border .ant-modal-content {
  border-radius: 10px;
}

.site-page-header {
  color: white;
}
.step-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}
.steps-progress {
  width: 80%;
}
.steps-content {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 10px;
}
.section-card {
  width: 100%;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.section-header {
  font: normal normal 600 24px/29px Raleway;
}
.section-card-left {
  text-align: left !important;
}
.header-icon-container {
  display: flex;
  align-items: center;
}
.header-success {
  color: #49BB64;
  font: normal normal bold 23px/31px Open Sans;
}
.header-danger {
  color: #E53B34;
  font-size: 20px;
  font-weight: bold;
}
.input-field {
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
  border-radius: 4px;
  margin: 10px auto 20px;
}
.input-wrapper {
  background-color: #F2F2F2;
  border-radius: 8px;
  padding: 10px 20px;
}
.italic {
  font-style: italic;
}
.company-details {
  font-weight: bold;
  margin-top: 4px;
}
.detail-label {
  font: italic normal normal 16px/19px Open Sans;
}
.detail-value {
  font: normal normal 600 16px/19px Open Sans;
}
.section-bottom-button {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}
.to-the-right {
  margin-left: auto;
}
.rectangle-button {
  padding: 10px 100px;
  width: auto;
}
.rectangle-button.ant-btn:focus {
  color: #FFF;
  background-color: #2B61C4;
}
.rectangle-button.ant-btn-primary[disabled] {
  color: #FFF;
  background: #2b61c496;
  box-shadow: 0px 4px 0px #1e4389a1;
}
.rectangle-button-danger {
  color: #FFF;
  background: #D82323;
  box-shadow: 0px 4px 0px #610000;
  border-radius: 8px;
  border: none;
  height: auto;
  padding: 10px 100px;
}
.rectangle-button-danger.ant-btn:focus {
  color: #FFF;
  background-color: #d34949;
}
.rounded-button-primary {
  color: #2B61C4;
  background: #F1F1F1;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger {
  color: #E53B34;
  background: transparent;
  border: 1px solid #E53B34;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
.rounded-button-danger:hover {
  color: #E53B34;
  border: 1px solid #E53B34;
}
.link-button {
  margin: 10px auto;
}
.section-error {
  background-color: #FFCFCF;
  padding: 20px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-modal.modal-middle .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.ant-modal.modal-middle .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.detail-wrapper {
  padding-bottom: 10px;
}
.detail-wrapper span {
  display: block;
  font: italic normal normal 16px/19px Open Sans;
  padding-bottom: 8px;
}
.detail-wrapper p,
.detail-wrapper li {
  font: normal normal 600 16px/19px Open Sans;
}
.detail-wrapper ul {
  list-style-type: decimal;
  padding-left: 16px;
}
.vehicle-table .ant-table {
  margin-block: 32px;
}
.vehicle-table .ant-table th {
  font: normal normal bold 16px/22px Open Sans;
}
.vehicle-table .ant-table td {
  font: normal normal normal 16px/22px Open Sans;
}

.custom-disabled-button {
    &.ant-btn-variant-solid:disabled {
        background: #84a3dd !important;
        color: #ffffff !important;
    }
}

.custom-postlogin-card {
    .ant-card-body {
        padding: 32px 24px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

.button-wrapper {
  display: inline-block;
  pointer-events: none;
  /* Disable pointer events for the wrapper */
}
.add-new-user-button {
  pointer-events: auto;
  /* Re-enable pointer events for the button */
  cursor: pointer;
}
.add-new-user-button.ant-btn-variant-solid:disabled {
  color: lightgray !important;
  border: 1px solid lightgray !important;
}

.filter-result-indicate {
  display: flex;
  align-items: center;
}
.filter-result-indicate .delete-button.buttons:hover,
.filter-result-indicate .delete-button.buttons:focus {
  border: 1px solid #2b61c4 !important;
  color: #2b61c4 !important;
}
.filter-result-indicate p {
  font-style: italic;
  color: white;
  margin-bottom: 0px;
}
.select-search-wrapper.search-input-container {
  width: 20vw;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 55px;
}

.export-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.export-btn-container .add-new-user-button.export-report {
  display: flex;
  align-items: center;
}
.export-btn-container .add-new-user-button.export-report .ant-image {
  margin-right: 10px;
}
.italic-label {
  font-style: italic;
}
.bold-text {
  font-weight: bold;
  word-break: break-all;
}
.branch-filter-form .ant-col .ant-form-item-label {
  font-style: italic;
}
.branch-filter-form .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.branch-filter-form .ant-input {
  border: 1px solid #2b61c4;
  border-radius: 4px;
}
.branch-filter-form .ant-input-lg,
.branch-filter-form .ant-select-lg {
  font-size: 14px;
}
.breadcrumb-wrapper {
  margin-top: 60px;
}
.breadcrumb-wrapper a.ant-breadcrumb-link {
  color: #ffe331 !important;
}
.transfer-form .ant-form-item label {
  font: italic 16px / 22px "Open Sans";
}
.page-content .tips {
  font: normal normal normal 16px/22px Open Sans;
}
.report-table .delete-button.buttons.footer-button {
  color: #2b61c4;
  border-color: #2b61c4;
}
.ant-input-search-large .ant-input-search-button {
  height: 45px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  padding-bottom: 3px;
}
.branch-filter-form .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.branch-filter-form .ant-input {
  height: 50px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 50px;
}

.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
  height: 50px;
}
.form-item > * {
  height: 50px;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.page-content {
  padding: 0px 50px;
}
.ant-page-header-heading-title {
  font: normal normal 600 32px/37px Raleway;
}
.add-new-user-button {
  background: #2B61C4 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 0px #1E4489;
  border-radius: 8px;
  text-align: center;
  font: normal normal 600 16px/19px Raleway;
  color: #FFFFFF;
  padding: 0px 90px;
  min-height: 50px;
  border: none;
  flex: 0.1 1;
}
.add-new-user-button > span {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ant-input-group-wrapper {
  max-width: 50%;
}
.ant-form-inline .ant-form-item {
  margin-right: 0px;
}
.back-button {
  border: 1px solid #2B61C4;
  color: #2B61C4;
  font-weight: bold;
  min-width: 165px;
  font: normal normal 600 16px/20px Raleway;
}
.delete-button {
  border: 1px solid #D40000;
  background: transparent;
  color: #D40000;
  margin: 10px 40px;
}
.buttons {
  height: 52px;
  padding: 10px 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  font: normal normal 600 16px/20px Raleway;
}
.search-button {
  height: 50px;
  background: #2B61C4 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 0px;
  opacity: 1;
  border-color: #2B61C4;
}
.ant-table-footer {
  display: flex;
  justify-content: flex-end;
  background: #EEF5FC 0% 0% no-repeat padding-box;
  padding: 0px !important;
}
th {
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #131313;
  padding: 15px !important;
}
.ant-breadcrumb {
  text-align: left;
  font: normal normal 600 16px/19px Raleway;
  letter-spacing: 0px;
}
.ant-breadcrumb-link,
.ant-breadcrumb-separator {
  color: white !important;
}
.ant-breadcrumb-link:hover {
  color: #FFE331 !important;
}
.form-background {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}
.form-input-field {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #2B61C4;
  border-radius: 4px;
  padding: 13px !important;
}
.usermanagement-info-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.admin-add-new-user-button {
  background: #2B61C4 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 0px #1E4489;
  border-radius: 8px;
  text-align: center;
  font: normal normal 600 16px/19px Raleway;
  color: #FFFFFF;
  padding: 0px 90px;
  min-height: 50px;
  border: none;
  flex: 0.1 1;
}
.admin-add-new-user-button > span {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner {
  border-color: #2b61c4 !important;
}
.ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner::after {
  background-color: #2b61c4 !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner {
  border-color: #2b61c4 !important;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner::after {
  background-color: #2b61c4 !important;
}


.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
}
.date-wrapper {
  width: 40%;
}
.date-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.date-wrapper .ant-picker {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.dropdown-wrapper {
  width: 20%;
  padding-right: 20px;
  padding-top: 5px;
}
.dropdown-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.dropdown-wrapper .ant-select-selector {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.horizontal-line {
  width: 30px;
  height: 2px;
  background-color: #FFF;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ant-table-wrapper.report-table .ant-table,
.ant-table-wrapper.report-table .ant-table-content {
  border-radius: 8px;
}
.ant-table-wrapper.report-table th {
  font-weight: bold;
}
.ant-table-wrapper.report-table th.ant-table-cell {
  background-color: #DFDFDF;
}
.img-btn-text {
  margin-left: 8px;
}

.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
}
.date-wrapper {
  width: 40%;
}
.date-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.date-wrapper .ant-picker {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.dropdown-wrapper {
  width: 20%;
  padding-right: 20px;
  padding-top: 15px;
}
.dropdown-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.dropdown-wrapper .ant-select-selector {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.horizontal-line {
  width: 30px;
  height: 2px;
  background-color: #FFF;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ant-table-wrapper.report-table .ant-table,
.ant-table-wrapper.report-table .ant-table-content {
  border-radius: 8px;
}
.ant-table-wrapper.report-table th {
  font-weight: bold;
}
.ant-table-wrapper.report-table th.ant-table-cell {
  background-color: #DFDFDF;
}
.img-btn-text {
  margin-left: 8px;
}

.ant-card {
  text-align: center;
}
.ownership-type-card {
  cursor: pointer;
  margin: 5px;
}
.ownership-type-card-disabled {
  opacity: 0.5;
  margin: 5px;
}
.transaction-title-out {
  font: normal normal bold 32px/37px Raleway;
  letter-spacing: 0px;
  color: #00FFF7;
  text-transform: uppercase;
}
.transaction-additional-out {
  font: italic normal normal 32px/37px Raleway;
  letter-spacing: 0px;
  color: #00FFF7;
}
.transaction-type-out {
  font: normal normal 600 32px/37px Raleway;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.ant-steps-label-vertical .ant-steps-item-content {
  width: 100%;
}
.rounded-button-bordered {
  color: #2B61C4;
  background: #FFF;
  border: 1px solid #2B61C4;
  border-radius: 24px;
  height: auto;
  padding: 10px 50px;
}
/* Add this CSS to style the horizontal line and the word "OR" */
.horizontal-line {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
  /* Gray color for the line */
  margin: 16px 0;
  /* Adjust the margin as needed */
  position: relative;
}
.or-text {
  background-color: #fff;
  /* Background color for the word "OR" */
  padding: 0 10px;
  /* Adjust the padding as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Make the background round like a circle */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  /* Optional: Add a subtle shadow effect */
}

.pending-list-page .label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.pending-list-page .form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.pending-list-page .search-container > * {
  margin-right: 10px;
}
.pending-list-page .form-item {
  width: 30%;
}
.pending-list-page .date-wrapper {
  width: 40%;
}
.pending-list-page .date-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.pending-list-page .date-wrapper .ant-picker {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.pending-list-page .column-wrapper {
  margin-right: 5px;
}
.pending-list-page .dropdown-wrapper {
  background-color: white !important;
  padding: 13px 20px;
  border-radius: 4px;
}
.pending-list-page .dropdown-wrapper label {
  color: #000;
  font: italic 14px "Open Sans";
}
.pending-list-page .dropdown-wrapper .ant-select-selector {
  width: 100%;
  min-height: 32px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.pending-list-page .search-wrapper label {
  background-color: white ;
  border-radius: 4px;
  height: 100%;
  padding: 0 15px;
  margin-right: 5px;
  font: italic 14px "Open Sans";
}
.pending-list-page .search-wrapper .ant-input-search-button {
  width: 40px;
  border-color: #2B61C4;
  background: #2B61C4;
  box-shadow: none;
}
.pending-list-page .search-wrapper .ant-input-search-button:hover {
  border-color: #4096ff;
  background: #4096ff;
}
.pending-list-page .search-wrapper .ant-input-search-button .ant-btn-icon {
  color: white;
}
.pending-list-page .search-wrapper .ant-input-wrapper {
  height: 100%;
}
.pending-list-page .search-wrapper .ant-input-wrapper .ant-input-affix-wrapper {
  width: 150px;
  height: 100% !important;
}
.pending-list-page .search-wrapper .ant-input-wrapper .ant-input-group-addon {
  height: 100%;
  padding-bottom: 0px !important;
}
.pending-list-page .search-wrapper .ant-input-wrapper .ant-input-group-addon .ant-btn {
  height: 100%;
}
.pending-list-page .export-report.add-new-user-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 127px !important;
  padding: 0px 30px;
  box-shadow: none;
}
.pending-list-page .horizontal-line {
  width: 30px;
  height: 2px;
  background-color: #FFF;
  margin: auto;
}
.pending-list-page .back-button {
  margin-top: 16px;
}
@media only screen and (max-width: 600px) {
  .pending-list-page .form-item,
  .pending-list-page .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.pending-list-page .ant-table-wrapper.report-table .ant-table,
.pending-list-page .ant-table-wrapper.report-table .ant-table-content {
  border-radius: 8px;
}
.pending-list-page .ant-table-wrapper.report-table th {
  font-weight: bold;
}
.pending-list-page .ant-table-wrapper.report-table th.ant-table-cell {
  background-color: #DFDFDF;
}
.pending-list-page .img-btn-text {
  margin-left: 8px;
}

.page-renewroadtax-list * {
  font-family: Plus Jakarta Sans !important;
}
.page-renewroadtax-list .ant-page-header-heading-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.16px;
}
.page-renewroadtax-list .page-content {
  background-color: #F1F1F1;
}
.page-renewroadtax-list .page-content .right-wrap p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.08px;
}
.page-renewroadtax-list .page-content .right-wrap .renew-right-button {
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #2B61C4;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.07px;
  text-transform: uppercase;
  color: #2B61C4;
  background-color: transparent;
}
.page-renewroadtax-list .page-content .select-search-wrapper .ant-input-lg {
  border-radius: 4px 0 0 4px;
}
.page-renewroadtax-list .page-content .select-search-wrapper .ant-input-group-addon {
  border-radius: 4px;
}
.page-renewroadtax-list .page-content .select-search-wrapper .ant-input-search-large .ant-input-search-button {
  height: 48px !important;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group {
  position: relative;
  bottom: -0.2px;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper {
  border: 1px solid #2B61C4;
  height: 55px !important;
  color: #2B61C4;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper-checked {
  border-color: black !important;
  border-top: 4px solid black !important;
  -webkit-border-start: 3px solid black;
          border-inline-start: 3px solid black;
  -webkit-border-end: 3px solid black !important;
          border-inline-end: 3px solid black !important;
  border-bottom: 0;
  color: black;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper-checked:last-child {
  border-right: 0px !important;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper:first-child {
  -webkit-border-end: 3px solid;
          border-inline-end: 3px solid;
  border-end-start-radius: 0px;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper:last-child {
  -webkit-border-end: 1px solid #2B61C4;
          border-inline-end: 1px solid #2B61C4;
  position: relative;
  left: -3px;
  border-end-end-radius: 0px;
}
.page-renewroadtax-list .page-content .body-section .list-radio .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}
.page-renewroadtax-list .page-content .body-section .list-radio.scrolled {
  background-color: #fff;
  transition: background-color 0.3s ease-in;
}
.page-renewroadtax-list .page-content .body-section .body-empty {
  background-color: #FFF;
  display: flex;
  width: 100%;
  padding: 24px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.25);
}
.page-renewroadtax-list .page-content .body-section .body-empty .illus-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.page-renewroadtax-list .page-content .body-section .body-empty .illus-wrap .illus-icon {
  width: 300px;
  height: 200px;
}
.page-renewroadtax-list .page-content .body-section .body-empty .illus-wrap .empty-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  margin: 16px 0 0;
}
.page-renewroadtax-list .page-content .body-section .body-empty .renew-button {
  display: flex;
  width: 287px;
  height: 52px;
  min-width: 287px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.page-renewroadtax-list .page-content .body-section .body-fill .filter-section {
  display: flex;
  height: 70px;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border: 0.8px solid #f0f0f0;
  background: #FFF;
}
.page-renewroadtax-list .page-content .body-section .body-fill .filter-section .filter-button {
  color: #00622A;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #00622A;
  background: #F9FFF6;
  display: flex;
  flex-direction: row;
  height: 38px;
  padding: 7px 16px;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.page-renewroadtax-list .page-content .body-section .body-fill .filter-section .filter-button .side-value {
  border: 1px solid #15803D;
  padding: 2px 15px;
  border-radius: 8px;
  font-size: 12.64px;
  font-weight: 600;
  color: #15803D;
  background-color: transparent;
  line-height: 16px;
}
.page-renewroadtax-list .page-content .body-section .body-fill .transition-container {
  transition: background-color 0.3s ease;
}
.page-renewroadtax-list .page-content .body-section .body-fill .transition-container .transition-content {
  display: flex;
  align-items: center;
  padding: 16px 16px 20px 16px;
  grid-gap: 24px;
  gap: 24px;
}
.page-renewroadtax-list .page-content .body-section .body-fill .transition-container .transition-content .slide-to-end {
  margin-left: auto;
  transition: margin-left 0.3s ease;
}
.page-renewroadtax-list .page-content .body-section .body-fill .ant-table-wrapper .ant-table {
  border-radius: 0;
}
.page-renewroadtax-list .page-content .body-section .body-fill .ant-table-thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 202px;
  z-index: 2;
}
.page-renewroadtax-list .roadtax-modal {
  width: 388px !important;
  height: 200px !important;
}
.page-renewroadtax-list .roadtax-modal .ant-modal-root .ant-modal-wrap {
  background-color: rgba(0, 0, 0, 0.5);
}
.page-renewroadtax-list .roadtax-modal .ant-spin .ant-spin-dot-holder {
  color: #4EA59B !important;
  font-size: 96px !important;
}
.page-renewroadtax-list .roadtax-modal .ant-modal-content {
  height: 200px !important;
}
.transaction-modal * {
  font-family: Plus Jakarta Sans !important;
}
.transaction-modal .ant-modal-header {
  font-size: 20.25px;
  font-weight: 600;
  line-height: 32px;
}
.transaction-modal .ant-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.transaction-modal .ant-table-wrapper table {
  border-radius: 0;
  margin-top: 20px;
}
.transaction-modal .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-radius: 0;
}
.transaction-modal .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-radius: 0;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 1002;
}
.fullscreen-modal * {
  font-family: Plus Jakarta Sans !important;
}
.fullscreen-modal .ant-modal-content {
  padding: 0 24px;
}
.fullscreen-modal .cardTitle {
  text-align: start;
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 3px;
  line-height: 25.2px;
  letter-spacing: -0.105px;
}
.fullscreen-modal .indicatorText {
  text-align: start;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 22px;
  line-height: 25.2px;
}
.fullscreen-modal .roadtaxDetails {
  border: 0;
}
.fullscreen-modal .roadtaxDetails .ant-card-body {
  padding: 0;
}
.fullscreen-modal .roadtaxDetails .ant-form-item-label {
  text-align: start;
}
.fullscreen-modal .roadtaxDetails .ant-form-item-row {
  display: flex;
  flex-direction: column;
}
.fullscreen-modal .roadtaxDetails .renewalPeriod .ant-form-item-control {
  height: 100px;
}
.fullscreen-modal .deliveryDetails {
  margin-top: 40px;
  border: 0;
}
.fullscreen-modal .deliveryDetails .ant-card-body {
  padding-left: 0;
}
.fullscreen-modal .deliveryDetails .ant-card-body .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-modal .contactDetails {
  margin-top: 40px;
  border: 0;
}
.fullscreen-modal .contactDetails .ant-card-body {
  padding-left: 0;
}
.fullscreen-modal .contactDetails .ant-card-body .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-modal .radio-group-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #2b61c4;
  border-radius: 4px;
  width: 566px;
  height: 48px;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-in-form-item {
  border-radius: 4px;
  height: 48px;
  width: 283px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 600;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item {
  border: 2px solid #2b61c4;
  border-radius: 4px;
  height: 48px;
  width: 283px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  background-color: #eef5fc;
  transition: background-color 0.3s;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
  border: 2px solid #2B61C4;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #2b61c4;
  background-color: #fff;
  border-width: 2px;
}
.fullscreen-modal .radio-group-custom .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #2b61c4;
}
.section-block {
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}
.section-header {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 12px;
}
.section-content {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  grid-gap: 107px;
  gap: 107px;
}
.section-content-2 {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}
.field-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 8px;
}
.field-label {
  font-size: 16px;
  font-weight: 400;
  width: 180px;
  color: #333333;
}
.field-value {
  flex: 1 1;
  font-size: 16px;
  font-weight: 600;
}
.label {
  color: grey;
  font-style: italic;
  margin-bottom: 0;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.search-container > * {
  margin-right: 10px;
}
.form-item {
  width: 30%;
}
.date-wrapper {
  width: 40%;
}
.date-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.date-wrapper .ant-picker {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.dropdown-wrapper {
  width: 20%;
  padding-right: 20px;
  padding-top: 15px;
}
.dropdown-wrapper label {
  color: #FFF;
  font: italic 16px / 22px "Open Sans";
}
.dropdown-wrapper .ant-select-selector {
  width: 100%;
  min-height: 48px;
  border: 1px solid #2B61C4;
  border-radius: 4px;
}
.horizontal-line {
  width: 30px;
  height: 2px;
  background-color: #FFF;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .form-item,
  .ant-form-inline .ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
.ant-table-wrapper.report-table .ant-table,
.ant-table-wrapper.report-table .ant-table-content {
  border-radius: 8px;
}
.ant-table-wrapper.report-table th {
  font-weight: bold;
}
.ant-table-wrapper.report-table th.ant-table-cell {
  background-color: #DFDFDF;
}
.img-btn-text {
  margin-left: 8px;
}

.roadtax * {
  font-family: Plus Jakarta Sans !important;
}
.roadtax .ant-card {
  text-align: start;
  box-shadow: 0px 4px 8px 0px #33333340;
  border-radius: 4px;
}
.roadtaxItem > .ant-card {
  box-shadow: none;
}
.roadtaxPurchase > .ant-card-body {
  padding: 0;
}
.roadtax-modal {
  width: 388px !important;
  height: 200px !important;
}
.roadtax-modal .ant-modal-root .ant-modal-wrap {
  background-color: rgba(0, 0, 0, 0.5);
}
.roadtax-modal .ant-spin .ant-spin-dot-holder {
  color: #4EA59B !important;
  font-size: 96px !important;
}
.roadtax-modal .ant-modal-content {
  height: 200px !important;
}
.result-modal {
  width: 388px !important;
  height: 362px !important;
}
.result-modal > div {
  height: 100%;
}
.result-modal .ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.owner-detail-header {
  font-size: 24px;
  font-weight: bold;
}
.roadtax .ant-steps .ant-steps-item .ant-steps-item-title span {
  color: white;
  position: relative;
  right: 80px !important;
}
.roadtax .ant-steps .ant-steps-item:last-child .ant-steps-item-title span {
  right: -15px !important;
}
.vehicle_information .ant-card-body {
  padding: 0;
}
.roadtax .cardTitle {
  text-align: start;
  font-size: 21px;
  margin-top: 0;
  margin-bottom: 3px;
  line-height: 25.2px;
  letter-spacing: -0.105px;
}
.roadtax .indicatorText {
  text-align: start;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 22px;
  line-height: 25.2px;
}
.roadtax .ant-form-item label {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.08px;
}
.radio-group-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #2b61c4;
  border-radius: 4px;
  width: 566px;
  height: 48px;
}
.radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-in-form-item {
  border-radius: 4px;
  height: 48px;
  width: 283px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 600;
}
.radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item {
  border: 2px solid #2b61c4;
  border-radius: 4px;
  height: 48px;
  width: 283px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  background-color: #eef5fc;
  transition: background-color 0.3s;
}
.radio-group-custom .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.radio-group-custom .ant-radio-wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
  border: 2px solid #2B61C4;
}
.radio-group-custom .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #2b61c4;
  background-color: #fff;
  border-width: 2px;
}
.radio-group-custom .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #2b61c4;
}
.approve-radio-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #2b61c4;
  border-radius: 4px;
  width: 340px;
  height: 48px;
}
.approve-radio-group .ant-radio-wrapper {
  border-radius: 4px;
  height: 48px;
  width: 170px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 600;
}
.approve-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.approve-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked {
  border: 2px solid #2b61c4;
  border-radius: 4px;
  height: 48px;
  width: 170px;
  align-items: center;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  background-color: #eef5fc;
  transition: background-color 0.3s;
}
.approve-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.approve-radio-group .ant-radio-wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
  border: 2px solid #2B61C4;
}
.approve-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #2b61c4;
  background-color: #fff;
  border-width: 2px;
}
.approve-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #2b61c4;
}
.formPurchase > *:not(:first-child) {
  margin-top: 24px;
}
.roadtaxPurchase .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type="checkbox"],
.roadtaxPurchase .ant-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
}
.roadtaxPurchase .ant-checkbox-checked .ant-checkbox-inner:after {
  -webkit-transform: rotate(45deg) scale(1.4) translate(-50%, -50%);
          transform: rotate(45deg) scale(1.4) translate(-50%, -50%);
}
.roadtaxPurchase .anticon svg {
  width: 24px;
  height: 24px;
}
.payment-container .ant-card {
  text-align: center;
}
.payment-card > .ant-card-body {
  padding: 0;
}
.payment-inner-card .ant-card-body {
  padding: 0;
}
.detail-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.07px;
  margin: 0 0 4px 0;
}
.detail-title-2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.07px;
  margin: 0 0 4px 0;
}
.item-description-card > .ant-card-body {
  padding: 0 !important;
}
.payment-table {
  background-color: transparent;
  box-shadow: none !important;
  border: none;
  margin: 0 auto;
}
.payment-table > .ant-card-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment-table-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.07px;
  padding: 24.04px 0px 0px !important;
  margin: 0;
}
.payment-table-title-row,
.item-description-row,
.subtotal-row,
.total-amount-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.item-description-row {
  background-color: #F9F9F9;
}
.subtotal-row {
  background-color: #EFEFEF;
}
.total-amount-row {
  background-color: #6A4799;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 64px;
}
.item-description,
.item-subtotal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 760px;
}
.approval-modal {
  width: 386px !important;
  height: 386px !important;
}
.approval-modal .ant-modal-content {
  padding: 52px 13px 32px;
}
.payment-table-p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.08px;
  padding: 12px 0px 0px !important;
  text-align: start;
  margin: 0;
}
.payment-table-p span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.07px;
  padding: 0px !important;
  text-align: start;
}
.payment-table-p-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  padding: 10px 0px 0px !important;
  margin: 0;
}
.item-description-row .item-description:first-child .payment-table-p {
  padding-top: 10px !important;
}
.item-description-row .item-description:last-child .payment-table-p {
  padding-bottom: 10px !important;
}
.subtotal-row .item-subtotal:first-child .payment-table-p,
.subtotal-row .item-subtotal:first-child .payment-table-p-2 {
  padding-top: 8px !important;
}
.subtotal-row .item-subtotal:last-child .payment-table-p,
.subtotal-row .item-subtotal:last-child .payment-table-p-2 {
  padding-bottom: 8px !important;
}
.total-amount-row .payment-table-p-2 {
  color: #FFFFFF;
  padding: 0 !important;
}
.total-amount-row .payment-table-p {
  color: #FFFFFF;
  font-size: 20px;
  padding: 0 !important;
}
.success-message h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30.24px;
  letter-spacing: -0.12px;
  margin: 16px 0 8px;
}
.success-message p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.08px;
  margin: 8px 0 16px;
}

.ant-page-header-heading-title,
.panel-text {
  color: white;
}
@media only screen and (max-width: 600px) {
  .ant-layout-header {
    padding: 20px;
  }
}
.usermanagement-info-modal .ant-modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.custom-landing-card {
    .ant-card {
        width: 100%;
        margin-bottom: 16px;
    }

    .ant-card .ant-card-head {
        background: #e8f5f5;
    }

    .ant-card .ant-card-body {
        padding: 16px 22px 22px 22px;
    }

    .ant-image {
        align-self: center;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.005em;
        display: flex;
        align-items: center;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }

    h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }

    a {
        font-size: 18px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.005em;
        text-decoration: underline;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    ul li {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.005em;

        span {
            font-weight: 600;
        }
    }
}

.custom-right-card {
    .ant-card {
        height: 49%;
    }
}

.custom-landing-modal {
    ul {
        -webkit-padding-start: 24px;
                padding-inline-start: 24px;

        li {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.005em;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #757575;

        span {
            font-weight: 600;
        }
    }
}

.custom-required-documents {
    .ant-card .ant-card-body {
        padding: 0px !important;
    }

    .tab-header {
        display: flex;
    }

    .tab-item {
        padding: 10px 15px;
        cursor: pointer;
        flex: 1 1;
        text-align: center;
        color: #2b61c4;
        font-size: 18px;
        font-weight: 600;
        background-color: #eef5fc;
        border: 1px solid #2b61c4;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        align-content: center;
    }

    .tab-item.active {
        background-color: #ffffff;
        border-left: none;
        border-bottom: none;
        border-right: none;
        border-top: 4px solid #333333;
        color: #333333;
    }

    .tab-item-lists {
        text-align: left;
        ul {
            padding: 0 0 0 18px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;

            span {
                font-weight: 700;
            }

            li:not(:last-child) {
                padding-bottom: 16px;
            }

            li {
                list-style-type: disc;
            }
        }

        p {
            color: #333333;
            font-weight: 700;
            font-size: 16px;
            line-height: 24.2px;
            margin-bottom: 8px;
        }

        /* Classes on bigger screen size */
        @media (min-width: 768px) {
            p {
                font-size: 18px;
                line-height: 30.24px;
            }

            ul {
                font-size: 18px;
                line-height: 22.68px;
                padding-left: 36px;
                padding-right: 24px;

                li:not(:last-child) {
                    padding-bottom: 12px;
                }
            }
        }
    }
}

.custom-landing-timeline {
    .ant-timeline {
        margin-left: 30px;
    }

    .ant-timeline .ant-timeline-item-tail {
        -webkit-border-start: 1px dashed #16467d;
                border-inline-start: 1px dashed #16467d;
    }
}

/* .custom-register-card {
    .ant-card {
        padding-bottom: 16px;
        min-width: 60%;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.005em;
        display: flex;
        align-items: center;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }
} */

.landing-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.24px;
    text-align: center;
    margin-bottom: 8px;

    @media (min-width: 768px) {
        font-size: 32px;
    }
}

.landing-subtitle {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.68px;
    text-align: center;

    /* Classes on bigger screen size */
    @media (min-width: 768px) {
        font-size: 20px;
    }

    a {
        color: inherit; /* Inherit the parent's color */
        font-weight: 600;
        text-decoration: underline;
    }
}

.landing-lists {
    text-align: left;
    ul {
        padding: 0 0 0 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

        span {
            font-weight: 700;
        }

        li:not(:last-child) {
            padding-bottom: 16px;
        }

        li {
            list-style-type: disc;
        }
    }

    p {
        color: #333333;
        font-weight: 700;
        font-size: 16px;
        line-height: 24.2px;
        margin-bottom: 8px;
    }

    /* Classes on bigger screen size */
    @media (min-width: 768px) {
        p {
            font-size: 18px;
            line-height: 30.24px;
        }

        ul {
            font-size: 18px;
            line-height: 22.68px;

            li:not(:last-child) {
                padding-bottom: 12px;
            }
        }
    }
}

.my-custom-card .ant-card-body {
    padding: 12px 12px 0px 12px;
}

