.custom-landing-card {
    .ant-card {
        width: 100%;
        margin-bottom: 16px;
    }

    .ant-card .ant-card-head {
        background: #e8f5f5;
    }

    .ant-card .ant-card-body {
        padding: 16px 22px 22px 22px;
    }

    .ant-image {
        align-self: center;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.005em;
        display: flex;
        align-items: center;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }

    h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }

    a {
        font-size: 18px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.005em;
        text-decoration: underline;
        user-select: none;
    }

    ul li {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.005em;

        span {
            font-weight: 600;
        }
    }
}

.custom-right-card {
    .ant-card {
        height: 49%;
    }
}

.custom-landing-modal {
    ul {
        padding-inline-start: 24px;

        li {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.005em;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #757575;

        span {
            font-weight: 600;
        }
    }
}

.custom-required-documents {
    .ant-card .ant-card-body {
        padding: 0px !important;
    }

    .tab-header {
        display: flex;
    }

    .tab-item {
        padding: 10px 15px;
        cursor: pointer;
        flex: 1;
        text-align: center;
        color: #2b61c4;
        font-size: 18px;
        font-weight: 600;
        background-color: #eef5fc;
        border: 1px solid #2b61c4;
        user-select: none;
        align-content: center;
    }

    .tab-item.active {
        background-color: #ffffff;
        border-left: none;
        border-bottom: none;
        border-right: none;
        border-top: 4px solid #333333;
        color: #333333;
    }

    .tab-item-lists {
        text-align: left;
        ul {
            padding: 0 0 0 18px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;

            span {
                font-weight: 700;
            }

            li:not(:last-child) {
                padding-bottom: 16px;
            }

            li {
                list-style-type: disc;
            }
        }

        p {
            color: #333333;
            font-weight: 700;
            font-size: 16px;
            line-height: 24.2px;
            margin-bottom: 8px;
        }

        /* Classes on bigger screen size */
        @media (min-width: 768px) {
            p {
                font-size: 18px;
                line-height: 30.24px;
            }

            ul {
                font-size: 18px;
                line-height: 22.68px;
                padding-left: 36px;
                padding-right: 24px;

                li:not(:last-child) {
                    padding-bottom: 12px;
                }
            }
        }
    }
}

.custom-landing-timeline {
    .ant-timeline {
        margin-left: 30px;
    }

    .ant-timeline .ant-timeline-item-tail {
        border-inline-start: 1px dashed #16467d;
    }
}

/* .custom-register-card {
    .ant-card {
        padding-bottom: 16px;
        min-width: 60%;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.005em;
        display: flex;
        align-items: center;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.005em;
        margin: 0;
    }
} */
